/**
 * 値の検証に用いるロジックをまとめておくモジュール。
 * このファイルは、サーバとクライアントで共通利用しています。
 */
(function() {
  'use strict';

  function Validators() {
  }

  /**
   * ユーザ名に使用できる文字クラス
   */
  Validators.USERNAME_ALLOWED_CHARS = 'a-zA-Z0-9_';

  /**
   * ユーザー名の最小長
   */
  Validators.USERNAME_MIN_LEN = 4;

  /**
   * ユーザー名の最大長
   */
  Validators.USERNAME_MAX_LEN = 32;

  /**
   * パスワードの最小長
   */
  Validators.PASSWORD_MIN_LEN = 6;

  /**
   * パスワード名の最大長
   */
  Validators.PASSWORD_MAX_LEN = 64;

  /*eslint-disable max-len */
  Validators.EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  /*eslint-enable max-len */

  Validators.validateEmail = function(str) {
    return Validators.EMAIL_PATTERN.test(str);
  };

  // 最初一文字は英数字だけとする。
  var REGEX_USERNAME = new RegExp('^[a-zA-Z0-9][' + Validators.USERNAME_ALLOWED_CHARS + ']{' +
      // 最初の1文字分引く
      (Validators.USERNAME_MIN_LEN - 1) + ',' +
      (Validators.USERNAME_MAX_LEN - 1) + '}$');

  Validators.validateUsername = function(username) {
    if (!username) {
      return false;
    }
    if (typeof username !== 'string') {
      return false;
    }
    return REGEX_USERNAME.test(username);
  };

  var REGEX_PASSWORD = new RegExp(
    '^[a-zA-Z0-9\\`\\~\\!\\@\\#\\$\\^\\%\\&\\*\\(\\)\\_\\-\\+' +
    '\\=\\{\\}\\[\\]\\\\\\|\\:\\;\\"\\\'\\?\\/\\<\\>\\,\\.\\¥]{' +
    Validators.PASSWORD_MIN_LEN + ',' +
    Validators.PASSWORD_MAX_LEN + '}$');
  Validators.validatePassword = function(password) {
    if (!password) {
      return false;
    }
    if (typeof password !== 'string') {
      return false;
    }
    return REGEX_PASSWORD.test(password);
  };

  if (typeof module !== 'undefined') {
    module.exports = Validators;
  } else if (typeof window !== 'undefined') {
    window.techfeed = window.techfeed || {};
    window.techfeed.validators = Validators;
  }
})();
